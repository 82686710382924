@font-face {
    font-family: "Amarillo";
    src: url("./fonts/Amarillo.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-Light.ttf");
    font-weight: 300;
    font-style: normal;
}


@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-Medium.ttf");
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}


@font-face {
    font-family: "RobotoMono";
    src: url("./fonts/RobotoMono-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "RobotoMono";
    src: url("./fonts/RobotoMono-Medium.ttf");
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: "Lato";
    src: url("./fonts/Lato-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: "Lato";
    src: url("./fonts/Lato-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}


@font-face {
    font-family: "Lato";
    src: url("./fonts/Lato-Black.ttf");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat-Regular.ttf");
}

body, html {
    overflow-x: hidden;
    scroll-behavior: smooth;
}
li, ul {
    list-style: none;
}
a{
    text-decoration: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif !important;
}

*::-webkit-scrollbar {
    width: 6px;
}
*::-webkit-scrollbar-track {
    background-color: inherit;
}
*::-webkit-scrollbar-thumb {
    background: #bababa;
    border-radius: 6px;
}

.pre-loader {
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.d-flex{
    display: flex;
    align-items: center;
}